import React from 'react'
import { graphql, Link } from 'gatsby'
import { format } from 'date-fns'
import { getBlogUrl, upperCase } from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
// import SEO from '../components/seo'
import PortableText from '../components/portableText'
import Layout from '../containers/layout'
import TitleAndDescription from '../components/TitleAndDescription'

export const query = graphql`
  query categoryPageQuery($category: String) {
    categories: allSanityPost(filter: {categories: {elemMatch: {title: {eq: $category}}}}) {
      edges {
        node {
          id
          publishedAt
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    category: sanityCategory(title: {eq: $category}) {
      _rawDescription
      title
    }
  }
`

const CategoryPage = props => {
  const { data, errors, pageContext } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const UpperCaseTitle = upperCase(data.category.title)

  // const site = (data || {}).site

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   )
  // }
  return (
    <Layout>
      {/* <SEO title={site.title} description={site.description} keywords={site.keywords} /> */}
      <Container>
        {/* <h3 className='mt-4 mb-5 text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:leading-9"'>{upperCase(pageContext.category)}</h3> */}
        <TitleAndDescription title={UpperCaseTitle} excerpt={data.category._rawDescription} />
        {data.categories.edges.map(({ node }) => {
          return (
            <div className='mb-10'>
              <p className='text-sm leading-5 text-gray-500'>
                <time dateTime='2020-03-16'>{format(node.publishedAt, 'MMMM Do, YYYY')}</time>
              </p>
              <Link to={getBlogUrl(node.publishedAt, node.slug.current)} className='block'>
                <h3 className='mt-0 text-xl leading-7 font-semibold text-gray-900 hover:text-gray-700'>{node.title}</h3>
                <div className='mt-1 text-base leading-6 text-gray-500'>
                  <PortableText blocks={node._rawExcerpt} />
                </div>
              </Link>
              {/* <div className='mt-2'>
                <Link
                  to={getBlogUrl(node.publishedAt, node.slug.current)}
                  className='text-sm leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150'
                >
                  Read this article →
                </Link>
              </div> */}
            </div>

          )
        })}
        {/* <h1 hidden>Welcome to {site.title}</h1> */}
        {/* <div className='my-10' /> */}
        {/* <CategoryList />
        {postNodes && (
          <BlogPostPreviewList title='' nodes={postNodes} browseMoreHref='/archive/' />
        )} */}
        {/* <Pagination currentPage={pageContext.currentPage} totalCount={data.posts.totalCount} /> */}

      </Container>
    </Layout>
  )
}

export default CategoryPage
