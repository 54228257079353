import React from 'react'
import PortableText from '../components/portableText'
// import '../styles/content.css'

const TitleAndDescription = ({ title, excerpt }) => {
  return (
    <div>
      <h1 hidden>Welcome to Gulcan {title} blog</h1>
      <h3 className='mt-4 mb-0 text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:leading-9"'>{title}</h3>
      <div className='prose lg:prose-md mb-8'>
        <PortableText blocks={excerpt} />
      </div>

    </div>
  )
}

export default TitleAndDescription
